<script lang="ts">
  import SearchButtom from "./SearchButton.svelte";
  import Location from "./Location.svelte";
  import Distance from "./Distance.svelte";
  import { searchState } from "../store/global";
  import type { SearchModel } from "../models/search.model.js";
  import { navigateWithState } from "../utils/navigation.utils";
  import { triggerQueryEvent, triggerSectionClickEvent } from "../utils/events";

  let state: SearchModel = {
    location: null,
    distance: 50,
    searchParam: "",
    latLngLiteral: null,
    typedAddress: "",
  };

  let inputValue = "";

  let input1;

  searchState.subscribe((value) => {
    inputValue = value.searchParam;
  });

  function updateStore(searchParam: string) {
    searchState.update((state) => {
      return {
        ...state,
        searchParam,
      };
    });
  }

  function handleInputChange() {
    triggerQueryEvent(inputValue);
    updateStore(inputValue);
  }

  searchState.subscribe((value) => {
    state = value;
  });

  function handleSubmit() {
    navigateWithState(state);
  }
</script>

<div role="search"  class="mxs-w-auto md:mxs-hidden mxs-z-40">
  <div class="mxs-flex mxs-flex-col mxs-rounded-full">
    <div
      class="mxs-flex mxs-mb-2 mxs-h-[58px] mxs-rounded-full mxs-bg-white mxs-shadow-lg mxs-gap-2"
    >
      <div class="mxs-w-4/6">
        <Location />
      </div>
      <div class="mxs-w-2/6">
        <Distance />
      </div>
    </div>
    <form
      autocomplete="off"
      on:submit|preventDefault={() => {
        handleSubmit();
      }}
      class=" mxs-flex mxs-h-[58px] mxs-shadow-lg mxs-items-center mxs-flex-grow mxs-pr-1.5 mxs-pl-6 mxs-rounded-full mxs-cursor-pointer mxs-transition hover:mxs-bg-gray-100 mxs-bg-white"
    >
      <div
        class="mxs-flex-grow"
        on:click={() => {
          input1.focus();
          triggerSectionClickEvent();
        }}
      >
        <label
          for="maxsold-navbar-search"
          class="mxs-my-0 mxs-text-xs mxs-font-bold mxs-block mxs-h-4 mxs-leading-4"
          >Search</label
        >
        <input
          bind:this={input1}
          bind:value={inputValue}
          on:input={handleInputChange}
          class="mxs-p-0 mxs-w-full mxs-bg-transparent mxs-text-base mxs-text-gray-600 mxs-font-light mxs-appearance-none mxs-border-none focus:mxs-outline-none active:mxs-outline-none"
          name="search"
          id="maxsold-navbar-search"
          placeholder="Search by product "
        />
      </div>
      <div class="mxs-flex-shrink-0">
        <SearchButtom />
      </div>
    </form>
  </div>
</div>

<div role="search" class="mxs-hidden md:mxs-block mxs-z-40">
  <div
    class="mxs-w-full mxs-h-[58px] md:mxs-h-[70px] mxs-flex items-center mxs-rounded-full mxs-bg-white mxs-shadow-lg"
  >
    <div class="mxs-w-2/5">
      <Location />
    </div>
    <div class="mxs-w-1/5">
      <Distance />
    </div>
    <form
      autocomplete="off"
      style="padding-right: 7px;"
      on:submit|preventDefault={() => {}}
      class=" mxs-flex mxs-items-center mxs-h-[58px] md:mxs-h-[70px] mxs-flex-grow mxs-box-border mxs-pl-7 mxs-rounded-full mxs-cursor-pointer mxs-transition hover:mxs-bg-gray-100"
    >
      <div
        class="mxs-flex-grow"
        on:click={() => {
          input1.focus();
          triggerSectionClickEvent();
        }}
      >
        <label
          for="maxsold-navbar-search"
          class="mxs-my-0 mxs-text-xs mxs-font-bold mxs-block mxs-h-4 mxs-leading-4"
          >Search</label
        >
        <input
          bind:this={input1}
          bind:value={inputValue}
          on:input={handleInputChange}
          class="mxs-p-0 mxs-w-full mxs-bg-transparent mxs-text-base mxs-text-gray-600 mxs-font-light mxs-appearance-none mxs-border-none focus:mxs-outline-none active:mxs-outline-none"
          name="search"
          id="maxsold-navbar-search"
          placeholder="Search by product"
        />
      </div>
      <div class="mxs-flex-shrink-0">
        <SearchButtom />
      </div>
    </form>
  </div>
</div>
