<script lang="ts">
  import type { LocationData } from "../models/location-data.model.js";
  import { searchState } from "../store/global";
  import LocationInput from "./LocationInput.svelte";
  import LocationDrodownTree from "./LocationDrodownTree.svelte";
  import { clearLocationCookies } from "../utils/cookies.js";
  import { triggerSectionClickEvent } from "../utils/events.js";

  let showDropdown = false;
  let currentLocation: LocationData | null = null;
  let currentLocationText = "";
  let typedAddress: string = "";

  searchState.subscribe((value) => {
    currentLocation = value.location;
    typedAddress = value.typedAddress;
    if (currentLocation) {
      const country = currentLocation.Country;
      const state = currentLocation.State;
      const region = currentLocation.Region;

      if (country && state && region) {
        currentLocationText = region;
      } else if (country && state) {
        currentLocationText = state;
      } else if (country) {
        currentLocationText = country;
      } else {
        currentLocationText = "";
      }
    }
  });

  function handleLocationSectionClick() {
    triggerSectionClickEvent();
    showDropdown = true;
  }

  function clearLocation() {
    clearLocationCookies();
    searchState.update((state) => {
      return {
        ...state,
        location: null,
        latLngLiteral: null,
        typedAddress: "",
      };
    });
  }
</script>

<div
  on:click={handleLocationSectionClick}
  class="mxs-h-[58px] md:mxs-h-[70px] mxs-pl-6 cursor-pointer mxs-rounded-full mxs-transition mxs-cursor-pointer hover:mxs-bg-gray-100 mxs-flex mxs-items-center"
>
  <div class="mxs-flex-grow mxs-items-center">
    <!-- show input if not location is selected -->
    {#if currentLocation === null}
      <LocationInput />
    {:else}
      <!-- if location value was select, show title and selected location -->
      <div class="mxs-w-full mxs-overflow-hidden">
        <p
          class="mxs-my-0 mxs-text-xs mxs-font-bold maxsold-h6 mxs-h-4 mxs-leading-4"
        >
          Location
        </p>
        <p
          class="mxs-my-0 mxs-text-gray-600 mxs-font-light maxsold-h4 mxs-h-6 mxs-leading-6 mxs-whitespace-nowrap"
        >
          {currentLocationText}
        </p>
      </div>
    {/if}
  </div>
  {#if !(typedAddress === "" || typedAddress === null) || currentLocation !== null}
    <div class="mxs-pr-2">
      <button
        on:click={clearLocation}
        class="mxs-w-6 mxs-h-6 mxs-rounded-full mxs-cursor-pointer mxs-bg-gray-300 hover:mxs-bg-gray-400 mxs-flex mxs-items-center mxs-justify-center"
        tabindex="0"
      >
        <svg
          class="mxs-w-3 mxs-h-3 mxs-cursor-pointer"
          preserveAspectRatio="none"
          data-bbox="65.35 65.35 69.3 69.3"
          viewBox="65.35 65.35 69.3 69.3"
          xmlns="http://www.w3.org/2000/svg"
          data-type="shape"
          ><title>Clear location selection</title>
          <g>
            <path
              d="M134.65 128.99L105.66 100l28.99-28.99-5.66-5.66L100 94.34 71.01 65.35l-5.66 5.66L94.34 100l-28.99 28.99 5.66 5.66L100 105.66l28.99 28.99 5.66-5.66z"
            />
          </g>
        </svg>
      </button>
    </div>
  {/if}
</div>

{#if typedAddress === "" || typedAddress === null}
  {#if showDropdown}
    <LocationDrodownTree
      on:closeDropdown={() => {
        showDropdown = false;
      }}
    />
  {/if}
{/if}
