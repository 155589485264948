<script lang="ts">
  import type { LocationData } from "../models/location-data.model.js";
  import { clickOutside } from "../directives/clickOutside.js";
  import { searchState } from "../store/global.js";
  import { createEventDispatcher } from "svelte";
  import {
    getCountries,
    getRegionsByState,
    getStatesByCountry,
  } from "../utils/locations.utils.js";
  import { saveLocationDataCookie } from "../utils/cookies.js";
  import { triggerLocationEvent } from "../utils/events.js";

  const dispatch = createEventDispatcher();

  let secondLevelOpen = "";
  let thirdLevelOpen = "";
  let currentLocation: LocationData | null = null;

  searchState.subscribe((value) => {
    currentLocation = value.location;
  });

  function closeDropdown() {
    dispatch("closeDropdown");
  }

  // updates store with selected location
  function setLocation(location: LocationData, shouldClose = false) {
    updateStoreLocation(location);
    triggerLocationEvent(location);
    saveLocationDataCookie(location);
    if (shouldClose) {
      closeDropdown();
    }
  }

  // updates store with selected location
  function updateStoreLocation(location: LocationData) {
    searchState.update((state) => {
      return {
        ...state,
        location,
      };
    });
  }
</script>

<div
  role="listbox"
  use:clickOutside
  on:click_outside={closeDropdown}
  class="maxsold-location-modal mxs-mt-2 mxs-text-base mxs-py-8  lg:mxs-mt-4 mxs-z-50 mxs-absolute mxs-font-light mxs-rounded-xl mxs-bg-white mxs-shadow-lg mxs-mr-4 mxs-transition-all mxs-min-w-[300px]"
>
  {#each getCountries() as level1}
    <div
      class="hover:mxs-bg-gray-100 mxs-px-8 mxs-cursor-pointer"
      on:click={(e) => {
        e.stopPropagation();
        setLocation(level1);
        secondLevelOpen === level1.Country
          ? (secondLevelOpen = "")
          : (secondLevelOpen = level1.Country);
      }}
    >
      <button
        role="option"
        class="mxs-block mxs-cursor-pointer mxs-py-1.5 "
        tabindex="0"
        aria-expanded={secondLevelOpen === level1.Country}
      >
        <p
          class="mxs-text-base mxs-my-0 {currentLocation &&
          currentLocation.Country === level1.Country &&
          currentLocation.Region === null &&
          currentLocation.State === null
            ? 'mxs-underline'
            : ''}"
        >
          {level1.Country}
        </p>
      </button>
    </div>
    {#if secondLevelOpen === level1.Country}
      <div role="listbox">
        {#each getStatesByCountry(level1.Country) as level2}
          <div
            class="hover:mxs-bg-gray-100 mxs-pr-8 mxs-pl-12 mxs-cursor-pointer"
            on:click={(e2) => {
              e2.stopPropagation();
              setLocation(level2);
              thirdLevelOpen === level2.State
                ? (thirdLevelOpen = "")
                : (thirdLevelOpen = level2.State);
            }}
          >
            <button
              role="option"
              class="mxs-block mxs-cursor-pointer mxs-py-1.5"
              tabindex="0"
              aria-expanded={thirdLevelOpen === level2.State}
            >
              <p
                class="mxs-my-0 mxs-text-gray-600 mxs-text-base {currentLocation &&
                currentLocation.State === level2.State &&
                currentLocation.Region === null
                  ? 'mxs-underline'
                  : ''}"
              >
                {level2.State}
              </p>
            </button>
          </div>
          {#if thirdLevelOpen === level2.State}
            <div role="listbox">
              {#each getRegionsByState(level2.State) as level3}
                <div
                  class="hover:mxs-bg-gray-100 mxs-pr-8 mxs-pl-16 mxs-cursor-pointer"
                  on:click={() => setLocation(level3, true)}
                >
                  <button
                    role="option"
                    type="button"
                    class="mxs-block mxs-cursor-pointer mxs-py-1.5"
                    tabindex="0"
                  >
                    <p
                      class="mxs-text-gray-500 mxs-my-0 mxs-text-base {currentLocation &&
                      currentLocation.Region === level3.Region
                        ? 'mxs-underline'
                        : ''}"
                    >
                      {level3.Region}
                    </p>
                  </button>
                </div>
              {/each}
            </div>
          {/if}
        {/each}
      </div>
    {/if}
  {/each}
</div>
