<script lang="ts">
  import { sidebarState } from "../store/sidebar";

  export let show = false;

  function showSidebar() {
    sidebarState.update((state) => {
      return {
        show: true,
      };
    });
  }
</script>

<button
  role="button"
  aria-haspopup="dialog"
  aria-expanded={show}
  on:click={showSidebar}
>
  <span class="visually-hidden">Open sidebar</span>
  <svg
    class="mxs-w-6 mxs-h-6 mxs-cursor-pointer"
    preserveAspectRatio="xMidYMid meet"
    data-bbox="20 20 160 160"
    viewBox="20 20 160 160"
    height="200"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
    data-type="shape"
    ><title id="svgcid-ir5hlc-eju81e" />
    <g>
      <path
        d="M57.587 38.794c0 10.379-8.414 18.794-18.794 18.794S20 49.173 20 38.794 28.414 20 38.794 20s18.793 8.414 18.793 18.794z"
      />
      <path
        d="M118.794 38.794c0 10.379-8.414 18.794-18.794 18.794s-18.794-8.414-18.794-18.794S89.621 20 100 20s18.794 8.414 18.794 18.794z"
      />
      <path
        d="M180 38.794c0 10.379-8.414 18.794-18.794 18.794-10.379 0-18.794-8.414-18.794-18.794S150.827 20 161.206 20C171.586 20 180 28.414 180 38.794z"
      />
      <path
        d="M118.794 100c0 10.379-8.414 18.794-18.794 18.794S81.206 110.379 81.206 100 89.621 81.206 100 81.206s18.794 8.415 18.794 18.794z"
      />
      <path
        d="M180 100c0 10.379-8.414 18.794-18.794 18.794-10.379 0-18.794-8.414-18.794-18.794s8.414-18.794 18.794-18.794S180 89.621 180 100z"
      />
      <path
        d="M57.587 161.206c0 10.379-8.414 18.794-18.794 18.794S20 171.586 20 161.206c0-10.379 8.414-18.794 18.794-18.794s18.793 8.415 18.793 18.794z"
      />
      <path
        d="M118.794 161.206C118.794 171.585 110.38 180 100 180s-18.794-8.414-18.794-18.794c0-10.379 8.414-18.794 18.794-18.794s18.794 8.415 18.794 18.794z"
      />
      <path
        d="M180 161.206c0 10.379-8.414 18.794-18.794 18.794-10.379 0-18.794-8.414-18.794-18.794 0-10.379 8.414-18.794 18.794-18.794 10.38.001 18.794 8.415 18.794 18.794z"
      />
      <path
        d="M57.587 100c0 10.379-8.414 18.794-18.794 18.794S20 110.379 20 100s8.414-18.794 18.794-18.794S57.587 89.621 57.587 100z"
      />
    </g>
  </svg>
</button>
