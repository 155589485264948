<script lang="ts">
  import { fly } from "svelte/transition";
  import { ExternalURLs } from "../data/urls";
  import { sidebarState } from "../store/sidebar";
  function hideSidebar() {
    sidebarState.update((state) => {
      return {
        show: false,
      };
    });
  }

  sidebarState.subscribe((value) => {
    if (value.show) {
    }
  });
</script>

<div
  role="dialog"
  aria-modal="true"
  aria-labelledby="Sidebar navigation"
  aria-describedby="Sidebar navigation contains main and secondary links"
  class="mxs-box-border mxs-fixed mxs-p mxs-top-0 mxs-right-0 mxs-h-screen mxs-bg-white mxs-overflow-y-auto mxs-w-screen mxs-z-50 mxs-flex mxs-flex-col"
  transition:fly={{ x: -640, opacity: 1 }}
>
  <div class="mxs-p-8 mxs-flex-grow">
    <div class="mxs-flex mxs-justify-between mxs-items-center mxs-mb-16">
      <a href={ExternalURLs.homepage}>
        <svg
          role="img"
          aria-label="Maxsold home page"
          class="mxs-w-auto mxs-h-8"
          preserveAspectRatio="xMidYMid meet"
          data-bbox="8.57 19.67 841.43 158.57"
          viewBox="8.57 19.67 841.43 158.57"
          height="2.64in"
          width="12.04in"
          xmlns="http://www.w3.org/2000/svg"
          data-type="color"
        >
          <g>
            <path
              fill="#30333a"
              d="M474.63 138.84c0 6.82 4.78 15.92 21.61 15.92 11.82 0 17.28-5.46 17.28-10.92 0-7.5-8.87-9.32-20.47-11.14-20.69-3.64-48.44-5.91-48.44-37.53 0-20.92 18.65-37.75 48.22-37.75 29.79 0 48.21 15 50 38.66h-31.58c-.68-8.41-3.87-14.78-18-14.78-9.1 0-16.6 3-16.6 10.24 0 8.64 12.05 10.46 26.15 13 20 3.64 44.35 7.73 44.35 33.89 0 27.74-23.2 39.34-52.54 39.34-31.61 0-51.39-16.6-52.08-38.89z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M676.61 117.46c0 33.66-27.06 60.73-62.76 60.73-35.94 0-62.55-26.61-62.55-60.73 0-33.88 26.84-60.95 62.55-60.95s62.76 27.07 62.76 60.95zm-94.15 0c0 18.65 13 32.52 30.7 32.52s32.07-13.87 32.07-32.52c0-18.87-13.65-32.29-31.38-32.29s-31.39 13.42-31.39 32.29z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M685.05 175.46V19.67h31.84v155.79z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M818.34 166.81c-8.19 6.37-20.7 10.24-34.12 10.24a59 59 0 0 1-58.9-59.36c0-33.66 27.06-60 58.9-60 13.42 0 25.93 4.09 34.12 10.46V19.67H850v155.79h-31.66zm.45-49.12c0-17.28-14.32-31.39-31.84-31.39-17.28 0-30.7 14.11-30.7 31.39 0 17.51 13.42 31.38 30.7 31.38 18.2 0 31.84-13.87 31.84-31.38z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M39.73 71.52A45.17 45.17 0 0 1 72 57.88c13.42 0 25 4.77 33.44 15.92 9.09-9.78 21.15-15.92 38.2-15.92 28.43 0 45.49 18.42 45.49 46.39v72.32h-31.37V109.5c0-13.64-7-22.51-19.55-22.51-14.33 0-23.66 9.55-23.66 25.24v64.36H83.39V109.5c0-13.64-7-22.51-19.55-22.51-14.33 0-23.66 9.55-23.66 25.24v64.36H8.57V59.92h31.16z"
              data-color="1"
            />
            <path
              fill="#30333a"
              d="M292.88 168c-8.19 6.37-20.7 10.24-34.11 10.24a59 59 0 0 1-58.91-59.36c0-33.66 27.06-60 58.91-60 13.41 0 25.92 4.1 34.11 10.47v-9.43h31.61v116.67h-31.61zm-62.09-49.12c0 17.51 13.42 31.38 30.7 31.38 18.2 0 31.85-13.87 31.85-31.38 0-17.29-14.33-31.39-31.85-31.39-17.28-.05-30.7 14.05-30.7 31.34z"
              data-color="1"
            />
            <path
              fill="#e13b35"
              d="M367.73 59.7h-35.94l39.46 58.33 17.97-26.56-21.49-31.77z"
              data-color="2"
            />
            <path
              fill="#30333a"
              d="m389.22 144.59 21.49 31.78h35.93l-39.45-58.34-17.97 26.56z"
              data-color="1"
            />
            <path
              fill="#e13b35"
              d="M331.79 176.37h35.94l21.49-31.78-17.97-26.56-39.46 58.34z"
              data-color="2"
            />
            <path
              fill="#30333a"
              d="M446.64 59.7h-35.93l-21.49 31.77 17.97 26.56 39.45-58.33z"
              data-color="1"
            />
            <path
              fill="#c72127"
              d="m389.22 91.47-17.97 26.56 17.97 26.56 17.97-26.56-17.97-26.56z"
              data-color="3"
            />
          </g>
        </svg>
      </a>
      <button tabindex="0" on:click={hideSidebar}>
        <svg
          class="mxs-w-6 mxs-h-6 mxs-cursor-pointer"
          preserveAspectRatio="none"
          data-bbox="65.35 65.35 69.3 69.3"
          viewBox="65.35 65.35 69.3 69.3"
          xmlns="http://www.w3.org/2000/svg"
          data-type="shape"
          ><title id="svgcid-41epj3j2f8yu">Close sidebar navigation</title>
          <g>
            <path
              d="M134.65 128.99L105.66 100l28.99-28.99-5.66-5.66L100 94.34 71.01 65.35l-5.66 5.66L94.34 100l-28.99 28.99 5.66 5.66L100 105.66l28.99 28.99 5.66-5.66z"
            />
          </g>
        </svg>
      </button>
    </div>
    <nav
      aria-label="primary links"
      class="mxs-flex mxs-flex-col mxs-items-start mxs-text-2xl mxs-gap-5"
    >
      <a
        tabindex="0"
        class="maxsold-link mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 mxs-text-maxsold-accent-default hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.auctions}>Auctions</a
      >
      <a
        tabindex="0"
        class="maxsold-link mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.sellEverything}>Sell</a
      >
      <a
        tabindex="0"
        class="maxsold-link mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.partners}>Partners</a
      >
      <a
        tabindex="0"
        class="maxsold-link mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.enterprise}>Enterprise</a
      >
      <a
        tabindex="0"
        class="maxsold-link mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.about}>About</a
      >
      <a
        tabindex="0"
        class="maxsold-link mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.support}>Support</a
      >
    </nav>
  </div>
  <nav
    aria-label="legal links"
    class="mxs-bg-gray-100 mxs-p-8 mxs-flex mxs-flex-col mxs-text-sm mxs-font-light mxs-text-gray-200"
  >
    <a
      class="maxsold-link mxs-mb-5 mxs-text-gray-500 mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
      href={ExternalURLs.termsAndConditions}>Terms and Conditions</a
    >
    <a
      class="maxsold-link mxs-mb-5 mxs-text-gray-500 mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
      href={ExternalURLs.legalDisclaimer}>Legal Disclaimer</a
    >
    <a
      class="maxsold-link mxs-mb-5 mxs-text-gray-500 mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
      href={ExternalURLs.privacyPolicy}>Privacy Policy</a
    >
  </nav>
  <button class="hidden-button " tabindex="0" on:click={hideSidebar}>
    Close sidebar navigation
  </button>
</div>
