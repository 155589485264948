<script lang="ts">
  import SectionNavbar from "./components/SectionNavbar.svelte";
  import SearchNavbar from "./components/SearchNavbar.svelte";
  import { onMount } from "svelte";
  import { sections } from "./data/sections";
  import { sidebarState } from "./store/sidebar";
  import { googlePlacesAPIKey } from "./config/config.js";

  import SidebarContent from "./components/SidebarContent.svelte";
  import { googleApiState } from "./store/googleApi";
  import { getUserCookie } from "./utils/cookies";
  export let sectionKey: string;
  export let extendedScroll: boolean;
  export let partnerLogoURI: string;
  export let partnerTitle: string;
  let userName = "";
  let isAuthenticated = false;
  const apiKey = googlePlacesAPIKey;

  let validatedSection: string | undefined = undefined;
  let showSidebar = false;

  sidebarState.subscribe((value) => {
    showSidebar = value.show;
  });

  onMount(() => {
    const index = sections.findIndex((s) => s.sectionKey === sectionKey);
    if (index >= 0) {
      validatedSection = sections[index].sectionKey;
    } else {
      validatedSection = "";
    }
    const userCookie = getUserCookie();
    if (userCookie) {
      userName = userCookie;
      isAuthenticated = true;
    }
  });

  function loaded() {
    googleApiState.set({ loaded: true });
  }
</script>

<svelte:head>
  <script
    src="https://maps.googleapis.com/maps/api/js?key={apiKey}&libraries=places"
    on:load={loaded}></script>
</svelte:head>

<header>
  <a class="skip-main" href="#main">Skip to main content</a>
  {#if validatedSection !== "" && validatedSection !== undefined}
    <SectionNavbar
      sectionKey={validatedSection}
      {userName}
      {isAuthenticated}
      {partnerLogoURI}
      {partnerTitle}
    />
  {:else}
    <div class="mxs-mb-[-212px] md:mxs-mb-[-158px] lg:mxs-mb-[-150px]">
      <SearchNavbar
        {partnerLogoURI}
        {partnerTitle}
        {userName}
        {isAuthenticated}
        {extendedScroll}
      />
    </div>
  {/if}
</header>

{#if showSidebar}
  <SidebarContent />
{/if}
