<script lang="ts">
  import { ExternalURLs } from "../data/urls";
</script>

<div
  class="mxs-hidden lg:mxs-block mxs-w-full mxs-animated mxs-animatedFadeInUp mxs-fadeInUp mxs-pt-5"
>
  <nav>
    <div class="mxs-flex mxs-items-center mxs-justify-center mxs-font-medium">
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.auctions}>Auctions</a
      >
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.sellEverything}>Sell</a
      >
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.partners}>Partners</a
      >
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.enterprise}>Enterprise</a
      >
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.about}>About</a
      >
      <a
        class="maxsold-link mxs-mx-4 mxs-mb-7 mxs-text-black mxs-font-bold mxs-no-underline mxs-transition mxs-duration-300 hover:mxs-text-maxsold-accent-default"
        href={ExternalURLs.support}>Support</a
      >
    </div>
  </nav>
</div>
