<script lang="ts">
  import WatchList from "./WatchList.svelte";
  import LoginMenu from "./LoginMenu.svelte";
  import UserMenu from "./UserMenu.svelte";
  import Search from "./Search.svelte";
  import Logo from "./Logo.svelte";
  import TopNavigation from "./TopNavigation.svelte";
  import Sidebar from "./Sidebar.svelte";
  import { onMount } from "svelte";
  import { searchState } from "../store/global";
  import { defaultDistanceInKm } from "../config/config";
  import { getLocationInformation } from "../utils/locations.utils";
  import {
    readLocationDataCookie,
    readLocationPlacesCookie,
  } from "../utils/cookies";
  import { triggerLatLngEvent, triggerLocationEvent } from "../utils/events";

  export let userName: string;
  export let isAuthenticated: boolean;
  export let extendedScroll: boolean;
  export let partnerLogoURI: string;
  export let partnerTitle: string;
  let y: number;
  let width: number;
  const screenHeight = window.innerHeight - 200;

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // try to build location
    const country = urlParams.get("country") ?? null;
    const locationState = urlParams.get("state") ?? null;
    const region = urlParams.get("region") ?? null;
    let locationData = getLocationInformation(country, locationState, region);

    // try to build query
    const query = urlParams.has("query")
      ? urlParams.get("query").replaceAll('"', "")
      : "";

    // try to get distance
    const distance = urlParams.has("distanceInKm")
      ? parseInt(urlParams.get("distanceInKm"))
      : defaultDistanceInKm;

    // build name if required
    let addressName = "";
    if (!locationData) {
      // no location was found, try to build search info from cookies

      // try to get locationData cookie
      const locationDataCookie = readLocationDataCookie();
      if (locationDataCookie) {
        locationData = locationDataCookie;
        // triggerLocationEvent(locationDataCookie);
      }

      // try to get places location dataå
      if (!locationData) {
        const cookie = readLocationPlacesCookie();
        if (cookie) {
          addressName = cookie.address;
          handleCookieInformation(cookie);
        }
      }
    }

    searchState.update((state) => {
      return {
        ...state,
        searchParam: query,
        location: locationData,
        distance: distance,
        typedAddress: addressName,
      };
    });
  });

  function handleCookieInformation(cookie: any) {
    if (
      cookie.lat &&
      cookie.lng &&
      typeof cookie.lat == "number" &&
      typeof cookie.lng == "number"
    ) {
      // if location was found from cookie, trigger event for other apps
      const latLng = {
        lat: cookie.lat as number,
        lng: cookie.lng as number,
      };
      // triggerLatLngEvent(latLng);
    }
  }
</script>

<svelte:window bind:scrollY={y} bind:innerWidth={width} />
<TopNavigation />
<div class="md:mxs-h-[148px] lg:mxs-h-[70px] mxs-mb-2">
  <div
    class="mxs-w-full mxs-animated mxs-animatedFadeInUp mxs-fadeInUp mxs-px-4  {y >
      48 && width >= 1024
      ? 'mxs-top-4 lg:mxs-fixed'
      : ''} {y > screenHeight + (extendedScroll ? 800 : 200) && width > 640
      ? 'mxs-animated-fast  mxs-fadeOut'
      : ''}"
  >
    <div
      style="margin-left: 20px; margin-right: 20px"
      class=" mxs-gap-4 mxs-flex mxs-justify-between mxs-items-center mxs-h-[70px] mxs-mx-auto  mxs-mb-2 mxs-relative"
    >
      <div class="lg:mxs-hidden">
        <Sidebar />
      </div>
      <div class="mxs-flex mxs-justify-center mxs-items-center mxs-gap-4">
        <Logo />
        {#if partnerLogoURI && partnerLogoURI !== ""}
          <div
            class=" mxs-hidden sm:mxs-flex sm:mxs-flex-col mxs-py-2 mxs-whitespace-nowrap"
          >
            <p class="mxs-my-2 mxs-text-sm mxs-text-center ">
              In <span class="mxs-font-bold">partnership</span> with
            </p>
            <div
              class="w-full mxs-flex-grow mxs-flex mxs-items-center mxs-justify-center"
            >
              <div
                class="mxs-w-full mxs-h-8 mxs-flex mxs-items-center mxs-justify-center"
              >
              <a href={'https://'+ partnerTitle} target="_blank">
                <img
                  class="mxs-h-12 mxs-min-w-full"
                  src={partnerLogoURI}
                  alt={partnerTitle}
                />  
                </a>
              </div>
            </div>
          </div>
        {/if}
      </div>

      <div
        style="max-width: 800px;"
        class="mxs-hidden lg:mxs-block mxs-flex-grow  mxs-max-w-[800px]"
      >
        <Search />
      </div>
      <div class="mxs-flex mxs-gap-4 ">
        <WatchList />
        {#if isAuthenticated}
          <UserMenu {userName} {isAuthenticated} />
        {:else}
          <LoginMenu />
        {/if}
      </div>
    </div>
    <div class="mxs-fixed" style="width: 1px; height: 1px" />


    {#if partnerLogoURI && partnerLogoURI !== ""}
    <div
      class="md:mxs-hidden  sm:mxs-flex sm:mxs-flex-col  mxs-whitespace-nowrap"
    >
      <p class="mxs-text-sm mxs-text-center ">
        In <span class="mxs-font-bold">partnership</span> with 
      </p>
      <div
        class="w-full mxs-flex-grow mxs-flex mxs-items-center mxs-justify-center"
      >
        <div
          class="mxs-w-full mxs-h-8 mxs-flex mxs-items-center mxs-justify-center"
        >
        <a href={'https://'+ partnerTitle} target="_blank" class="mxf-mb-20">
          <img
            class="mxs-h-12"
            src={partnerLogoURI}
            alt={partnerTitle}
          />
          </a>
        </div>
      </div>
    </div>
  {/if}





  </div>
  <div class=" mxs-h-[148px] md:mxs-h-[70px] ">
    <div style="padding-top: 24px;"
      class="mxs-px-4 lg:mxs-hidden mxs-grow mxs-transition-all mxs-duration-500 {y >
        60 && width < 1024
        ? 'mxs-top-4 mxs-fixed mxs-w-full '
        : ''} {y > screenHeight + (extendedScroll ? 800 : 200)
        ? 'mxs-opacity-0 mxs-pointer-events-none'
        : 'mxs-opacity-1 mxs-pointer-events-auto'}"
    >
      <Search />
    </div>
  </div>
</div>
