<script lang="ts">
  import { defaultDistanceInKm } from "../config/config.js";

  import {
    searchDistanceInKm,
    searchDistanceMiles,
  } from "../data/distances.js";

  import { clickOutside } from "../directives/clickOutside.js";

  import { searchState } from "../store/global";
  import { getMilesByKm } from "../utils/distances.utils.js";
  import {
    triggerDistanceEvent,
    triggerSectionClickEvent,
  } from "../utils/events.js";

  const distanceValues = searchDistanceInKm;
  let showDropdown = false;
  let currentDistance = defaultDistanceInKm;

  searchState.subscribe((value) => {
    currentDistance = value.distance;
  });

  function handleClick() {
    triggerSectionClickEvent();
    showDropdown = true;
  }

  function handleOutside() {
    if (showDropdown) {
      showDropdown = false;
    }
  }

  function setDistance(distance: number) {
    updateDistance(distance);
    showDropdown = false;
  }

  function updateStore(distance: number) {
    searchState.update((state) => {
      return {
        ...state,
        distance,
      };
    });
  }

  function updateDistance(distance: number) {
    currentDistance = distance;
    triggerDistanceEvent(distance);
    updateStore(distance);
  }
</script>

<button
  aria-haspopup="true"
  aria-expanded={showDropdown}
  aria-label="Menu for selecting distance"
  on:click={handleClick}
  class="mxs-w-full mxs-box-border mxs-pl-4 mxs-items-center mxs-h-[58px] md:mxs-h-[70px] mxs-rounded-full mxs-transition mxs-cursor-pointer hover:mxs-bg-gray-100"
>
  <div class="mxs-overflow-hidden">
    <p
      class="mxs-my-0 mxs-text-xs mxs-font-bold maxsold-h6 mxs-h-4 mxs-leading-4"
    >
      Distance
    </p>
    <p
      class="mxs-my-0 mxs-text-gray-600 mxs-font-light maxsold-h4 mxs-h-6 mxs-leading-6 mxs-whitespace-nowrap"
    >
      {getMilesByKm(currentDistance)} miles
    </p>
  </div>
</button>
{#if showDropdown}
  <div
    use:clickOutside
    on:click_outside={handleOutside}
    class="mxs-min-w-[190px] mxs-py-8 mxs-mt-2 lg:mxs-mt-4 mxs-bg-white mxs-rounded-xl mxs-absolute mxs-shadow-lg mxs-z-50"
  >
    <div class="mxs-text-light">
      <ul role="listbox" class="mxs-pl-0 mxs-list-none mxs-my-0">
        {#each distanceValues as km, i}
          <li
            on:click={() => {
              setDistance(km);
            }}
            role="option"
            class="hover:mxs-bg-gray-100 mxs-px-8 mxs-py-2 "
          >
            <button
              class=" mxs-cursor-pointer mxs-block mxs-text-base"
              tabindex="0"
            >
              {searchDistanceMiles[i]} miles
            </button>
          </li>
        {/each}
      </ul>
    </div>
  </div>
{/if}
