<script lang="ts">
  import { onMount } from "svelte";
  import type { LatLngLiteral } from "../models/latlng-literal.model";
  import { searchState } from "../store/global";
  import { googleApiState } from "../store/googleApi";
  import { saveLocationPlacesCookie } from "../utils/cookies";
  import { triggerLatLngEvent } from "../utils/events";
  import { getAddressName, getLatLngInfo } from "../utils/google-places.utils";

  let input;
  let addressInputValue = "";

  onMount(() => {
    googleApiState.subscribe((state) => {
      if (!state.loaded) {
        return;
      }
      if (!input) {
        return;
      }
      pacSelectFirst(input);
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setComponentRestrictions({
        country: ["us", "ca"],
      });
      autocomplete.setTypes(["(regions)"]);
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        // Emit the new address object for the updated place
        const place = autocomplete.getPlace();

        if (place && place.geometry) {
          const info = getLatLngInfo(place);
          const name = getAddressName(place);

          if (name && info) {
            addressInputValue = name;
            updateTypedAddress(name);
            updateStoreLatLng(info);
            triggerLatLngEvent(info);
            saveLocationPlacesCookie(info.lat, info.lng, name);
          }
        }
      });
    });
  });

  // https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
  function pacSelectFirst(input) {
    if (!input) {
      return;
    }
    // store the original event binding function
    var _addEventListener = input.addEventListener
      ? input.addEventListener
      : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
      // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
      // and then trigger the original listener.
      if (type == "keydown") {
        var orig_listener = listener;
        listener = function (event) {
          var suggestion_selected =
            document.querySelectorAll(".pac-item-selected").length > 0;
          if (event.which == 13 && !suggestion_selected) {
            input.dispatchEvent(
              new KeyboardEvent("keydown", {
                keyCode: 40,
                which: 40,
              })
            );
          }

          orig_listener.apply(input, [event]);
        };
      }

      _addEventListener.apply(input, [type, listener]);
    }

    input.addEventListener = addEventListenerWrapper;
    input.attachEvent = addEventListenerWrapper;
  }

  searchState.subscribe((value) => {
    addressInputValue = value.typedAddress;
  });

  function handleInputChange() {
    updateTypedAddress(addressInputValue);
  }

  function updateTypedAddress(text: string) {
    searchState.update((state) => {
      return {
        ...state,
        typedAddress: text,
      };
    });
  }

  function updateStoreLatLng(latlng: LatLngLiteral) {
    searchState.update((state) => {
      return {
        ...state,
        latLngLiteral: latlng,
      };
    });
  }
</script>

<div>
  <form
    autocomplete="off"
    on:submit|preventDefault={() => {}}
    class="mxs-w-full"
  >
    <label
      for="maxsold-location"
      class="mxs-block mxs-my-0 mxs-text-xs mxs-font-bold mxs-h-4 mxs-leading-4"
      >Location</label
    >
    <input
      type="text"
      autocomplete="off"
      id="maxsold-location"
      bind:this={input}
      bind:value={addressInputValue}
      on:input={handleInputChange}
      class="mxs-p-0 mxs-w-full mxs-bg-transparent mxs-text-base mxs-text-gray-600 mxs-font-light mxs-appearance-none mxs-border-none focus:mxs-outline-none active:mxs-outline-none"
      name="maxsold-location"
      placeholder="Location or ZIP/Postal Code"
    />
  </form>
</div>
