<script lang="ts">
  import { ExternalURLs } from "../data/urls.js";

  export let userName: string;
  export let isAuthenticated: boolean;
  import { clickOutside } from "../directives/clickOutside.js";

  let showDropdown = false;

  function handleClick() {
    showDropdown = !showDropdown;
  }

  function handleOutside() {
    if (showDropdown) {
      showDropdown = false;
    }
  }
</script>

<button
  aria-haspopup="true"
  aria-expanded={showDropdown}
  on:click={handleClick}
  use:clickOutside
  on:click_outside={handleOutside}
  class="{!showDropdown
    ? 'hover:mxs-scale-105'
    : ''} mxs-cursor-pointer sm:mxs-hidden"
>
  <span class="visually-hidden">Open user menu</span>

  <div
    class=" mxs-w-11 mxs-h-11 sm:mxs-w-9 sm:mxs-h-9 mxs-flex mxs-items-center mxs-justify-center mxs-bg-black hover:mxs-bg- mxs-rounded-full hover:mxs-bg-maxsold-yellow"
  >
    <svg
      class="mxs-h-6 mxs-w-6"
      preserveAspectRatio="xMidYMid meet"
      data-bbox="7.89 10.07 493.79 496.84"
      viewBox="7.89 10.07 493.79 496.84"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      aria-labelledby="svgcid-6z4hcjf7xxp1"
      ><title id="svgcid-6z4hcjf7xxp1" />
      <g>
        <path
          fill="#ffffff"
          d="M342.55 296.61a156.69 156.69 0 0 0 69-129.8c0-86.43-70.31-156.74-156.73-156.74S98.06 80.38 98.06 166.81a156.7 156.7 0 0 0 69 129.84A256.16 256.16 0 0 0 7.89 470a326.08 326.08 0 0 0 36.86 36.91c14.73-103.15 104.4-182 210.05-182a212.25 212.25 0 0 1 210.05 182A326.29 326.29 0 0 0 501.68 470a256.43 256.43 0 0 0-159.13-173.4zM254.8 279.9a113.1 113.1 0 1 1 113.1-113.09A113.22 113.22 0 0 1 254.8 279.9z"
          data-color="1"
        />
      </g>
    </svg>
  </div>
</button>
<button
  aria-haspopup="true"
  aria-expanded={showDropdown}
  on:click={handleClick}
  use:clickOutside
  on:click_outside={handleOutside}
  class="{!showDropdown
    ? 'hover:mxs-scale-105'
    : ''} mxs-hidden sm:mxs-flex mxs-justify-center  sm:mxs-pr-1.5 sm:mxs-pl-4 mxs-h-11 mxs-gap-4 sm:mxs-justify-between mxs-items-center mxs-relative mxs-bg-gray-100 mxs-rounded-full mxs-font-light mxs-cursor-pointer mxs-shadow mxs-transition mxs-duration-300"
>
  <span class="visually-hidden">Open user menu</span>

  <div
    class="mxs-w-8 mxs-h-8 mxs-items-center mxs-justify-center mxs-hidden sm:mxs-flex"
  >
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="33 40.882 134 118.236"
      viewBox="33 40.882 134 118.236"
      height="22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      data-type="shape"
      aria-hidden="true"
      ><title id="svgcid-69cl89eivnb3" />
      <g>
        <path d="M167 40.882v15.765H33V40.882h134z" />
        <path d="M167 92.118v15.765H72.412V92.118H167z" />
        <path d="M167 143.353v15.765H33v-15.765h134z" />
      </g>
    </svg>
  </div>
  <div
    class="mxs-w-11 mxs-h-11 sm:mxs-w-9 sm:mxs-h-9 mxs-flex mxs-items-center mxs-justify-center mxs-bg-black sm:mxs-bg-maxsold-accent-default mxs-rounded-full hover:mxs-bg-maxsold-yellow"
  >
    <svg
      class="mxs-h-6 mxs-w-6"
      preserveAspectRatio="xMidYMid meet"
      data-bbox="7.89 10.07 493.79 496.84"
      viewBox="7.89 10.07 493.79 496.84"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      aria-hidden="true"
      ><title id="svgcid-6z4hcjf7xxp1" />
      <g>
        <path
          fill="#ffffff"
          d="M342.55 296.61a156.69 156.69 0 0 0 69-129.8c0-86.43-70.31-156.74-156.73-156.74S98.06 80.38 98.06 166.81a156.7 156.7 0 0 0 69 129.84A256.16 256.16 0 0 0 7.89 470a326.08 326.08 0 0 0 36.86 36.91c14.73-103.15 104.4-182 210.05-182a212.25 212.25 0 0 1 210.05 182A326.29 326.29 0 0 0 501.68 470a256.43 256.43 0 0 0-159.13-173.4zM254.8 279.9a113.1 113.1 0 1 1 113.1-113.09A113.22 113.22 0 0 1 254.8 279.9z"
          data-color="1"
        />
      </g>
    </svg>
  </div>
</button>
{#if showDropdown}
  <div
    style="z-index: 999;"
    class=" mxs-min-w-[190px] mxs-mt-16 mxs-top-1 mxs-py-8 mxs-right-0 mxs-absolute mxs-rounded-xl mxs-bg-white mxs-shadow-lg"
  >
    <p class="mxs-mt-0 mxs-text-gray-400 mxs-font-thin mxs-px-8 mxs-mb-2">
      Account
    </p>
    <div class="mxs-text-light">
      <ul role="listbox" class="mxs-pl-0 mxs-list-none mxs-my-0">
        {#if isAuthenticated}
          <li
            role="option"
            class="mxs-py-2 mxs-cursor-pointer mxs-px-8 hover:mxs-bg-gray-100"
          >
            <a
              class="maxsold-link mxs-text-black mxs-no-underline"
              href={ExternalURLs.account}>{userName}</a
            >
          </li>
        {/if}
        <li class="mxs-py-2 mxs-cursor-pointer mxs-px-8 hover:mxs-bg-gray-100">
          <a
            class="maxsold-link mxs-text-black mxs-no-underline"
            href={ExternalURLs.logout}>Sign out</a
          >
        </li>
      </ul>
    </div>
  </div>
{/if}
