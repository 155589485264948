<script lang="ts">
  import type { SearchModel } from "../models/search.model";
  import { searchState } from "../store/global";
  import { navigateWithState } from "../utils/navigation.utils";

  let state: SearchModel = {
    location: null,
    distance: 50,
    searchParam: "",
    latLngLiteral: null,
    typedAddress: "",
  };

  searchState.subscribe((value) => {
    state = value;
  });

  function handleSubmit() {
    navigateWithState(state);
  }
</script>

<button
  class="mxs-h-12 mxs-w-12 md:mxs-h-14 md:mxs-w-14 mxs-flex mxs-items-center mxs-justify-center mxs-cursor-pointer mxs-border-none mxs-bg-maxsold-accent-default mxs-rounded-full mxs-transition mxs-duration-300   hover:mxs-bg-maxsold-yellow focus:mxs-bg-maxsold-accent-dark"
  type="submit"
  tabindex="0"
  on:click={handleSubmit}
>
  <span class="visually-hidden">Submit search</span>
  <svg
    aria-hidden="true"
    class="mxs-w-6 mxs-h-6"
    fill="none"
    stroke="white"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    viewBox="0 0 24 24"
  >
    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>
</button>
