<script lang="ts">
  import { ExternalURLs } from "../data/urls";
</script>

<a
  aria-label="Watchlist page"
  class="mxs-text-black mxs-no-underline"
  href={ExternalURLs.watchList}
>
  <span
    class="maxsold-link mxs-hidden sm:mxs-flex mxs-h-11 mxs-px-4 mxs-items-center mxs-bg-gray-100 mxs-cursor-pointer mxs-rounded-full mxs-font-light mxs-shadow mxs-transition mxs-duration-300 hover:mxs-scale-105 hover:mxs-bg-maxsold-yellow hover:mxs-text-white"
  >
    Watchlist
  </span>
  <div
    class="mxs-cursor-pointer sm:mxs-hidden mxs-h-11 hover:mxs-bg-maxsold-yellow mxs-w-11 mxs-rounded-full mxs-bg-black mxs-flex mxs-items-center mxs-justify-center "
  >
    <svg
      class="mxs-h-7 mxs-w-7 mxs-text-white"
      fill="white"
      preserveAspectRatio="xMidYMid meet"
      data-bbox="30 54 140 92"
      viewBox="30 54 140 92"
      height="200"
      width="200"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      aria-hidden="true"
    >
      <g>
        <path
          d="M100.001 146c-37.613 0-67.11-41.838-68.346-43.617L30 100.001l1.655-2.382C32.891 95.838 62.388 54 100.001 54c37.609 0 67.108 41.838 68.346 43.619l1.653 2.382-1.653 2.381C167.109 104.162 137.61 146 100.001 146zm-59.63-46.001c6.667 8.611 31.243 37.619 59.63 37.619 28.382 0 52.955-29.001 59.627-37.617-6.67-8.615-31.245-37.619-59.627-37.619-28.441 0-52.97 28.997-59.63 37.617z"
          fill="#ffffff"
          data-color="1"
        />
        <path
          d="M100.001 122.857c-12.681 0-22.996-10.25-22.996-22.854 0-12.606 10.316-22.861 22.996-22.861s22.996 10.255 22.996 22.861c0 12.604-10.315 22.854-22.996 22.854zm0-37.332c-8.031 0-14.564 6.496-14.564 14.478 0 7.98 6.533 14.474 14.564 14.474 8.032 0 14.564-6.494 14.564-14.474 0-7.982-6.532-14.478-14.564-14.478z"
          fill="#ffffff"
          data-color="1"
        />
      </g>
    </svg>
  </div>
</a>
